import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Menu.module.scss';
import { GatsbyImage } from "gatsby-plugin-image";
import Sticky from "react-stickynode";
import ExtLink from "./ExtLink";

const Menu = ({logo, items, instagram, site}) => (
    <Sticky enabled={true} top={0} innerZ={101}>
        <nav>
            <div className={styles.container}>
                <div className={styles.wrap}>
                    <a href={ "/#" } title={site.title} className={styles.logo}>
                        <GatsbyImage image={logo} className={styles.logoLogo}
                             imgStyle={{objectFit: 'contain'}} alt="Solo Preto e Indígena Internacional"/>
                    </a>
                    <div className={styles.items}>
                        {items.map(function (item, index) {
                            return <a key={index} href={item.to} title={item.name}
                                      aria-label={item.name} className={styles.itemsName}>{item.name}</a>
                        })
                        }
                    </div>
                    <ExtLink title={"Instagram @" + instagram.user} className={styles.instagram}
                             to={"https://www.instagram.com/solopretoeindigena/" + instagram.user}>
                        <GatsbyImage className={styles.instagramLogo} image={instagram.logo}
                             imgStyle={{objectFit: 'contain'}} alt={"Instagram @" + instagram.user} />
                        <span>Siga @<u>{instagram.user}</u></span>
                    </ExtLink>
                </div>
            </div>
        </nav>
    </Sticky>
);

Menu.propTypes = {
    logo: PropTypes.object,
    items: PropTypes.array,
    instagram: PropTypes.object,
    site: PropTypes.object
};

export default Menu;
